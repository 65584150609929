<template>
  <div class="contentbody">
    <b-loading is-full-page :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="contentleft" v-if="w.left" v-bind:class="{ contentleftbig: w.right }">
      <div class="contentleft-inner">
        <div class="contentleft-header-noflex">
          <div class="contentleft-header-searchbox">
            <b-input placeholder="Search" type="search" icon="search" v-model="searchField" @input="doSearch()" ></b-input>
          </div>
          <div class="pt7 pl7 pr7 isFlex isFlexAlign">
            <div><b-checkbox v-model="expertonly">Experts Only</b-checkbox></div>
            <div>
              <b-select placeholder="Select a country" v-model="searchCountry" @input="doSearch()">
                <option value="">All countries</option>
                <option
                  v-for="(option, index) in countries"
                  :value="option.countryCode"
                  :key="index">
                  {{ option.label }}
                </option>
              </b-select>
            </div>
            <template v-if="isadmin === 'true'">
              <!-- <div>
                <b-select placeholder="Publish status" v-model="searchPublish" @input="doSearch()">
                  <option value="">All</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Pending">Pending</option>
                </b-select>
              </div> -->
              <div>
                <b-select placeholder="Expert status" v-model="searchExpert" @input="doSearch()">
                  <option value="">All</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Pending">Pending</option>
                </b-select>
              </div>
            </template>
          </div>
        </div>

        <div class="contentleft-body scrolly scrollx">
          <div class="tsk lgreybg p11 m11" v-if="mindmapList.length === 0">No people found</div>
          
          <div class="tsk p0 m7 isFlex isFlexCol tskshadow dcard" v-for="(n, index) in mindmapList" :key="index">
            <div class="txtc mt11 mb5">
              <!-- image: start -->
              <div class="isFlex isFlexJustify" style="height: 50px;">
                <div>
                  <img src="/dummy/120x120.png" class="thumb thumb-sm thumb-circle" v-if="n.photo === ''" />
                  <img :src="n.photo" class="thumb thumb-sm thumb-circle" v-else />
                </div>
              </div>
              <!-- image: end  -->
            </div>
            <div class="isFlexGrow">
              <div class="txtc txtbold mb3 mt5">{{ n.firstname }} {{ n.lastname }}</div>
              <div class="txtc txtmd mb3">{{ n.oneliner }}</div>
              <div class="txtc txtsm mb3"><span v-if="n.town !== ''">{{ n.town }}, </span>{{ n.country }}</div>
            </div>
            <div class="isFlex isFlexWrap isFlexJustify vlgreybg roundedbottom p5">
              <router-link tag="button" :to="n.profileurl" class="button is-small is-light">
                View Profile
              </router-link>
              <!-- <a class="mr11 txtmd" :href="n.profileurl" target="_blank">View Profile</a> -->
              <b-button size="is-small" type="is-light" icon-left="edit" @click="openUser(n, index)" v-if="isadmin === 'true'">Edit</b-button>
            </div>
          </div>
        </div>

        <div class="p5 isFlex">
          <div class="isFlexGrow mr11">
            <b-pagination
              @change="getNetwork($event)"
              :total="total"
              :current="current"
              :range-before="rangeBefore"
              :range-after="rangeAfter"
              :order="order"
              :size="size"
              :simple="isSimple"
              :rounded="isRounded"
              :per-page="perPage"
              :icon-prev="prevIcon"
              :icon-next="nextIcon"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="contentright" v-if="w.right">
      <div class="contentright-inner">
        <div class="contentright-nav">
          <div class="iconbutton mv5" @click="closeRight()">
            <font-awesome-icon :icon="['fal', 'times']" size="lg" ></font-awesome-icon>
          </div>
          <div v-if="isadmin === 'true'">
            <div v-if="!isEdit">
              <div class="iconbutton mv5" @click="isEdit=true">
                <font-awesome-icon :icon="['fal', 'edit']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div v-if="isEdit">
              <div class="iconbutton mv5" @click="isEdit=false">
                <font-awesome-icon :icon="['fal', 'eye']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="iconbutton mv9 bluetxt11" @click="userSave()" v-if="canEdit && isEdit">
              <font-awesome-icon :icon="['fal', 'save']" size="lg" ></font-awesome-icon>
            </div>
            <div class="iconbutton mv9 bluetxt11" @click="CopyMindMap()" v-if="canEdit && isEdit">
              <font-awesome-icon :icon="['fal', 'copy']" size="lg" ></font-awesome-icon>
            </div>
            <div class="iconbutton mv9 red" @click="mindmapDelete()" v-if="canEdit && isEdit">
              <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
            </div>
          </div>
        </div>
        <div class="contentright-body ">
          <div class="contentright-body-body scrolly">

            <div class="p11">
              <div class="txtbold mb3 mt5">{{ ActiveUser.firstname }} {{ ActiveUser.lastname }}</div>
              <div class="txtmd mb3">{{ ActiveUser.email }}</div>
              <div class="txtmd mb3">{{ ActiveUser.oneliner }}</div>
              <div>
                <b-field label="Change package">
                  <b-select placeholder="Change Package" v-model="ActiveUser.planid" expanded>
                    <option
                      v-for="option in Products"
                      :value="option._id"
                      :key="option._id">
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
                <div>
                  <b-button icon-left="save" expanded @click="changeplan">Save</b-button>
                </div>
                <!-- <b-field label="Published">
                  <b-select v-model="ActiveUser.published" expanded>
                    <option value="No">No</option>
                    <option value="Pending">Pending</option>
                    <option value="Yes">Yes</option>
                  </b-select>
                </b-field>
                <b-field label="Rejection Message">
                  <b-input placeholder="Message" v-model="ActiveUser.publishedmessage" type="textarea" rows="2" size="is-small"></b-input>
                </b-field> -->
                <b-field label="Expert">
                  <b-select v-model="ActiveUser.expert" expanded>
                    <option value="No">No</option>
                    <option value="Pending">Pending</option>
                    <option value="Yes">Yes</option>
                  </b-select>
                </b-field>
                <div class="txtbold">Reason</div>
                <div class="mb5">{{ActiveUser.expertreason}}</div>
                <b-field label="Rejection Message">
                  <b-input placeholder="Message" v-model="ActiveUser.expertmessage" type="textarea" rows="2" size="is-small"></b-input>
                </b-field>
                <div>
                  <b-button icon-left="trash" expanded @click="userSave()">Save</b-button>
                </div>
              </div>
            </div>

          </div>
          <!-- <div class="p5 vlgreybg isFlex isFlexSpace" v-if="isEdit">
            <b-button @click="mindmapDelete" icon-left="trash" type="is-light" size="is-small">Delete</b-button>
            <b-button @click="userSave" icon-left="save" type="is-light" size="is-small">Save</b-button>
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import { Schemas } from "@/schemas/all.js";
import debounce from "lodash/debounce";
import array from "lodash/array";
import { windowSplit2 } from "../mixins/windowSplit.js";
import draggable from "vuedraggable";
import ModalMindMap from '../modals/ModalMindMap.vue';

Vue.component("pillbox", {
  template: "pillbox-template",
  props: {
    label: String,
    // value: undefined,
    width: { type: String, default: '100px' },
  },
  data: function () {
    return {
      lb: this.label,
      // vl: this.value,
      st: `width: ${this.width};`,
    }
  },
  template: `<div class="mb5 isFlex isFlexAlign"><div class="roundedleft lgreybg p5 pl11" style="width: 100px;">{{lb}}</div><div class="p5 isFlexGrow vlgreybg roundedright"><span><slot></slot></span></div></div>`});

export default {
  mixins: [Utils, windowSplit2, Schemas],
  components: {
    draggable
  },
  name: 'Mindmaps',
  data() {
    return {
      editor: null,
      mindmapList: [],
      ActiveUser: {},
      ActiveUserId: -1,
      ActiveId: -1,
      ActiveRow: {},
      isEdit: false,
      canEdit: false,
      showEditor: false,
      canEdit: false,
      isNew: false,
      pageW: 0,

      isLoading: true,
      GoalList: [],
      sub: localStorage.getItem("sub"),

      // pagination: start
      searchField: "",
      searchCountry: "",
      searchExpert: "",
      searchPublish: "",
      expertonly: false,
      perPage: 10,
      prodCount: 0,
      total: 0,
      current: 1,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      // pagination: end

      share: '',

      Products: [],

      isadmin: localStorage.getItem("isadmin"),
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getNetwork(0);
    this.getGoals();
    console.log('isadmin', this.isadmin);
  },
  methods: {
    
    async getGoals() {
      this.GoalList = [];

      const glin = await this.DataGet("/goals/select");
      const gl = glin;

      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        const nr = {
          _id: g._id,
          goaltitle: g.goaltitle,
          goalcolor: g.goalcolor
        }
        this.GoalList.push(nr);
      }
      if(!this.isNew) {
        this.GoalList.push({
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      });
      }
      this.loading = false;
    },

    doSearch: debounce(function() {
      this.current = 1;
      this.total = 0;
      this.getNetwork(0);
    }, 1000),

    async getNetwork(type) {
      this.isLoading = true;

      const sendData = {
        searchField: this.searchField,
        searchExpert: this.searchExpert,
        searchPublish: this.searchPublish,
        expertonly: this.expertonly,
        perPage: this.perPage,
        skip: 0
      };
      this.current = type;
      if (type === 0) {
        this.current = 1;
        let getcount = await this.DataSend('post','/network/listcount', sendData, 'rows', false);
        let totalRows = getcount;
        if (totalRows > this.perPage) {
          totalRows = totalRows / this.perPage;
          totalRows = Math.ceil(totalRows);
          this.total = totalRows * this.perPage;
        } else {
          this.total = totalRows;
        }
      }

      if (type > 1) {
        sendData.skip = this.perPage * (type - 1);
      }

      const gnotify = await this.DataSend('post', '/network', sendData, 'rows', false);

      for (const u of gnotify) {
        if (u.handle === '') {
          // u.profileurl = `${window.location.origin}/whois/${u._id}`;
          u.profileurl = `/whois/${u._id}`;
        } else {
          // u.profileurl = `${window.location.origin}/iam/${u.handle}`;
          u.profileurl = `/iam/${u.handle}`;
        }
      }
      
      this.mindmapList = gnotify;
      this.isLoading = false;
    },

    async openUser(row, index, isEdit=false) {
      this.getProducts();
      this.ActiveUserId = index;
      this.isLoading = true;
      const gc = await this.DataGet(`/user/${row._id}`);
      this.isNew = false;
      this.ActiveUser = gc;
      console.log('this.ActiveUser', this.ActiveUser);
      this.openRight();
      this.isLoading = false;
    },

    async getProducts() {
      this.Products = await this.DataGet('/products');
    },

    changeGoal(data){
      if (data !== '') {
        const gindex = array.findLastIndex(this.GoalList, { '_id': data });
        this.ActiveUser.goalid = data;
        this.ActiveUser.goaltitle = this.GoalList[gindex].goaltitle;
        this.ActiveUser.goalcolor = this.GoalList[gindex].goalcolor;
      } else {
        this.ActiveUser.goalid = '';
        this.ActiveUser.goaltitle = '';
        this.ActiveUser.goalcolor = '';
      }
    },

    async userSave() {
      const tosave = {
        _id: this.ActiveUser._id,
        // published: this.ActiveUser.published,
        // publishedmessage: this.ActiveUser.publishedmessage,
        expert: this.ActiveUser.expert,
        expertreason: this.ActiveUser.expertreason,
      }
      this.DataSend('post', '/user/updatefew', tosave, 'rows', true);
    },

    async changeplan() {
      const tosave = {
        _id: this.ActiveUser._id,
        // published: this.ActiveUser.published,
        // publishedmessage: this.ActiveUser.publishedmessage,
        newplan: this.ActiveUser.planid
      }
      this.DataSend('post', '/user/changeplan', tosave, 'rows', true);
    },

    async mindmapDelete() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this mind map? This cannot be undone.",
        confirmText: "Delete mind map",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.closeRight();
          const delmindmap = await this.DataDelete(`/mindmap/${this.ActiveUser._id}`, "row");
          this.mindmapList.splice(this.ActiveUserId, 1);
        }
      });
    },

    openUserModal(row, index) {
      this.ActiveRow = row;
      this.ActiveId = index;
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalMindMap,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: this.ActiveRow,
        },
        events: {
          dataFromModal: value => {}
        }
      });
    },

    


  },
  computed: {
    
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // EventBus.$off('task_open');
    // EventBus.$off('task_added');
  }
}
</script>

<style>
.ProseMirror {
  /* border: 1px solid #CCC; */
  min-height: 300px;
  padding: 5px;
}
.ProfileDesc > p{
  margin-bottom: 10px;
}
</style>
